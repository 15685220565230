<template>
  <div id="apply-hero">
    <div class="image-container w-screen relative flex justify-center items-center z-10">
      <div class="hero-image absolute w-full h-full top-0 left-0"></div>
      <div class="w-full h-full bg-dark_red opacity-60 absolute top-0 left-0 z-10 sm:opacity-60"/>
      <div class="flex z-20 flex-wrap w-9/12 justify-center h-full sm:flex-nowrap sm:flex-col sm:items-center
                  sm:justify-between py-16 sm:py-32">
        <img src="../../assets/images/Logo.png"
             alt="EESTEC Bucuresti Logo"
             class="w-36 z-20 mb-4 object-contain sm:hidden"
        >
        <p class="hidden sm:block text-white font-montserrat text-4-5xl font-semibold sm:mb-4">
          {{$t("apply.hero.motto")}}
        </p>
        <p class="text-center text-white font-roboto sm:hidden"
           v-html="$t('apply.hero.descriptionMobile')"></p>
        <p class="hidden text-center text-white font-roboto text-2xl max-w-4xl sm:block sm:mb-4"
           v-html="$t('apply.hero.descriptionDesktop')"></p>
        <ApplyButton class="sm:mb-4" :startRecruiting="false"/>
        <Countdown deadline="2024-10-13 23:59:59" :startRecruiting="false"/>
        <p class="text-center text-white font-roboto ">{{ $t('apply.hero.recruitingWillStart') }}</p>
        <p class="text-white font-montserrat text-2xl font-semibold sm:hidden"> {{$t("apply.hero.motto")}} </p>
      </div>
      <ScrollDownArrow/>
    </div>
  </div>
</template>

<script>
import ApplyButton from './components/ApplyButton.vue';
import Countdown from './components/Countdown.vue';
import ScrollDownArrow from '../global/Desktop/ScrollDownArrow.vue';

export default {
  components: {
    Countdown,
    ApplyButton,
    ScrollDownArrow,
  },
};
</script>

<style lang="scss" scoped>
.hero-image {
  background-image: url("https://i.imgur.com/AZ2ZGsX.jpg");
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.image-container {
  height: 105vh;
  //small screen ffs
  min-height: 764px;
  @media  screen and (min-width: 480px) {
    height: 100vh;
    max-height: 1080px;
  }
}
</style>
